import Image from 'next/image'
import { QuoteCardElementType } from 'library/types/strapi/components'
import React from 'react'
import Text from 'library/components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'

type Props = {
  card: QuoteCardElementType
}

const QuoteCarouselCard = ({ card }: Props) => (
  <Container>
    <CardContent>
      <Text tag='p' type='with-quote' color='white'>
        {card.body}
      </Text>

      <Text tag='p' type='body-small-400' color='white60'>
        {card.person}
      </Text>
    </CardContent>
    <ImageWrapper>
      <Image
        src={getStrapiMedia(card.image.data)}
        alt={card.image.data.attributes.alternativeText || ''}
        width={0}
        height={0}
        sizes='100vw'
        draggable={false}
      />
    </ImageWrapper>
  </Container>
)

export default QuoteCarouselCard

const Container = styled.div`
  flex-shrink: 0;
  width: 327px;
  height: 100%;
  min-height: 440px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 64px;
  column-gap: 16px;
  user-select: none;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 446px;
    min-height: 446px;
    column-gap: 24px;
    grid-template-columns: repeat(8, 1fr);
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 469px;
    min-height: 469px;
    grid-template-columns: repeat(6, 1fr);
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 588px;
    min-height: 588px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    width: 688px;
    min-height: 688px;
  }
`

const CardContent = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  padding: 24px 24px 80px 24px;
  border-radius: 24px 24px 0px 0px;
  background: ${({ theme }) => theme.colors.teal};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 40px 40px 64px 40px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 48px 48px 64px 48px;
    border-radius: 32px 32px 0px 0px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding: 64px 64px 80px 64px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding: 64px 64px 80px 64px;
  }
`

const ImageWrapper = styled.div`
  grid-column: 1 / -1;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 24px 24px;
    object-fit: cover;

    @media ${({ theme }) => theme.breakpoints.md} {
      border-radius: 0px 0px 32px 32px;
    }
  }
`
