import React, { useEffect, useState } from 'react'

import Image from 'next/image'
import { LogoElementType } from 'library/types/strapi/components'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'

const Logo = ({ name, image }: LogoElementType) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Card $isLoaded={loaded}>
      <ImageWrapper>
        <Image
          src={getStrapiMedia(image.data)}
          alt={name || image.data.attributes.alternativeText || ''}
          width={0}
          height={0}
          sizes='100vw'
        />
      </ImageWrapper>
    </Card>
  )
}

export default Logo

const Card = styled.div<{ $isLoaded: boolean }>`
  flex-shrink: 0;
  width: 160px;
  height: 100px;
  display: flex;
  visibility: ${({ $isLoaded }) => ($isLoaded ? 'visible' : 'hidden')};
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.navyLight};

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 200px;
    height: 126px;
    margin-right: 24px;
    border-radius: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 242px;
    height: 152px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`
