import Script from 'next/script'
import useConfig from 'helpers/useConfig'
import useCookieSettings from 'helpers/useCookieSettings'

type ConversionType = 'free-trial-request' | 'demo-request' | 'callback-request'

type Props = {
  type: ConversionType
}

export default function GoogleAnalyticsKeyEvent(props: Props) {
  const { type } = props

  const config = useConfig()

  const cookieSettings = useCookieSettings()

  if (!type || !config.googleAnalyticsMeasurementId || !type || !cookieSettings.analytics) {
    return null
  }

  return (
    <Script id='gtag-keyevent' strategy='afterInteractive'>
      {`
        gtag('event', 'submit_form', { event_category: 'Lead Capture', event_label: '${type}', value: 1 });
      `}
    </Script>
  )
}
