import Flex from 'library/components/Flex'
import Image from 'next/image'
import React from 'react'
import { SchemeType } from 'theme'
import { SolutionCardElementType } from 'library/types/strapi/components'
import Text from 'library/components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'
import useBreakpoint from 'hooks/useBreakpoint'

type SolutionCardsLayout = 5 | 3 | 2

type Props = {
  card: SolutionCardElementType
  layout: SolutionCardsLayout
}

const SolutionCard = ({ card, layout }: Props) => {
  const breakpoint = useBreakpoint()
  const scheme = useBlockScheme()

  const bodyTextType = ['lg', 'xl'].includes(breakpoint) ? 'body-400' : 'body-small-400'

  return (
    <Container $scheme={scheme} $layout={layout}>
      <Wrapper $scheme={scheme}>
        <ImageWrapper>
          <Image
            src={getStrapiMedia(card.image.data)}
            alt={card.image.data.attributes.alternativeText || ''}
            width={0}
            height={0}
            sizes='100vw'
            draggable={false}
          />
        </ImageWrapper>

        <Content column gap={8}>
          <Text tag='h4' color={scheme === 'dark' ? 'white' : 'charcoal'}>
            {card.heading}
          </Text>
          <Text tag='p' type={bodyTextType} color={scheme === 'dark' ? 'white60' : 'charcoal80'}>
            {card.body}
          </Text>
        </Content>
      </Wrapper>
    </Container>
  )
}

export default SolutionCard

const Content = styled(Flex)`
  padding: 8px 32px 40px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 8px 40px 48px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding: 8px 48px 48px;
  }
`

const Wrapper = styled.div<{ $scheme: SchemeType }>`
  background: ${({ $scheme, theme }) =>
    $scheme === 'dark'
      ? `radial-gradient(51.54% 52.41% at 50% 100%,rgba(255, 255, 255, 0.08) 0%, ${theme.colors.navyDark} 100%),rgba(255, 255, 255, 0.01)`
      : theme.colors.paleBlue};
`

const Container = styled.div<{ $scheme: SchemeType; $layout: SolutionCardsLayout }>`
  grid-column: 1 / -1;
  width: 100%;
  overflow: hidden;
  border-radius: 24px;
  background: ${({ $scheme, theme }) =>
    $scheme === 'dark'
      ? `radial-gradient(30% 52.41% at 50% 0%, rgba(255, 255, 255, 0.32) 0%, ${theme.colors.navyDark} 100%),rgba(255, 255, 255, 0.12) border-box`
      : theme.colors.paleBlue};
  border: 1px solid rgba(255, 255, 255, 0.12);

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: span 6;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: span 5;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    border-radius: 32px;
  }

  :first-child {
    @media ${({ theme }) => theme.breakpoints.sm} {
      grid-column: ${({ $layout }) => ($layout === 2 ? 'span 6' : '1 / -1')};
    }

    @media ${({ theme }) => theme.breakpoints.md} {
      grid-column: ${({ $layout }) => ($layout === 2 ? 'span 5' : '1 / -1')};
    }

    ${Content} {
      @media ${({ theme }) => theme.breakpoints.sm} {
        padding: ${({ $layout }) => $layout !== 2 && '8px 104px 40px'};
      }

      @media ${({ theme }) => theme.breakpoints.md} {
        padding: ${({ $layout }) => $layout !== 2 && '8px 154px 48px'};
      }

      @media ${({ theme }) => theme.breakpoints.lg} {
        padding: ${({ $layout }) => $layout !== 2 && '8px 154px 56px'};
      }
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 285px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.breakpoints.sm} {
    height: 273px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    height: 321px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    height: 340px;
  }

  img {
    width: auto;
    height: 100%;
    object-fit: cover;
  }
`
