import Script from 'next/script'
import useConfig from 'helpers/useConfig'
import useCookieSettings from 'helpers/useCookieSettings'

type ConversionType = 'free-trial-request' | 'demo-request' | 'callback-request'

type Props = {
  type: ConversionType
}

export default function LinkedinAdsConversion(props: Props) {
  const { type } = props

  const config = useConfig()

  const cookieSettings = useCookieSettings()

  const conversionIds = {
    'free-trial-request': config.linkedinFreeTrialRequestConversionId,
    'demo-request': config.linkedinDemoRequestConversionId,
    'callback-request': config.linkedinCallbackRequestConversionId,
  }

  const conversionId = conversionIds[type]

  if (!type || !config.linkedinPartnerId || !conversionId || !cookieSettings.marketing || !cookieSettings.analytics) {
    return null
  }

  return (
    <Script id='linkedin-conversion' strategy='afterInteractive'>
      {`
        let linkedinConversionId = ${conversionId};
        window.lintrk('track', { conversion_id: linkedinConversionId });
      `}
    </Script>
  )
}
