import { HeroSlideElementType } from 'library/types/strapi/components'
import Image from 'next/image'
import React from 'react'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'

type Props = {
  slide: HeroSlideElementType
  imagePriority?: boolean
}

const CarouselSlide = ({ slide, imagePriority }: Props) => (
  <ImageWrapper>
    <Image
      src={getStrapiMedia(slide.image?.data)}
      alt={slide.image.data.attributes.alternativeText || ''}
      width={0}
      height={0}
      sizes='100vw'
      priority={imagePriority}
    />
  </ImageWrapper>
)

export default CarouselSlide

const ImageWrapper = styled.div`
  grid-column: 1 / -1;
  width: 343px;
  height: 214px;
  position: relative;
  border: 3px solid #d8e6f7;
  border-radius: 8px;
  overflow: hidden;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 680px;
    height: 425px;
    border-width: 6px;
    border-radius: 14px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 960px;
    height: 600px;
    border-width: 8px;
    border-radius: 20px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 1120px;
    height: 700px;
    border-width: 10px;
    border-radius: 24px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`
