import { Controller, useForm } from 'react-hook-form'
import React, { useMemo, useState } from 'react'

import Button from 'library/components/Button'
import EmailRegex from 'library/regexp/EmailRegex'
import FieldWrapper from 'library/components/FieldWrapper'
import Flex from 'library/components/Flex'
import GoogleAdsConversion from 'layout/scripts/GoogleAdsConversion'
import GoogleAnalyticsKeyEvent from 'layout/scripts/GoogleAnalyticsKeyEvent'
import Icon from 'library/components/Icon'
import Input from 'library/components/Input'
import LinkedinAdsConversion from 'layout/scripts/LinkedinAdsConversion'
import NextLink from 'next/link'
import { PhoneNumberUtil } from 'google-libphonenumber'
import Text from 'library/components/Text'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'
import { useRouter } from 'next/router'

type FormFieldValues = {
  firstName: string
  lastName: string
  companyName: string
  email: string
  contactNumber: string
  companySize: string
  consentToMarketing: boolean
}

const Form = () => {
  const [requested, setRequested] = useState(false)
  const [loading, setLoading] = useState(false)

  const router = useRouter()

  const isBookADemoPage = router.pathname === '/book-a-demo'
  const formType = isBookADemoPage ? 'demo-request' : 'callback-request'
  const submitButtonText = isBookADemoPage ? 'Book a Demo' : 'Request Call Back'

  const consentToProcessText = `By clicking the "${submitButtonText}" button, you consent to allow Onetrace to store and process the personal information submitted above to provide you the content requested.`
  const consentToMarketingText = `We'd love to send you more information about Onetrace, our products and other relevant content. You can unsubscribe at any time.`
  const acceptMarketingText = `Yes, I would like to receive more information about Onetrace.`
  const declineMarketingText = `No, I do not wish to receive more information about Onetrace.`

  const scheme = useBlockScheme()
  const form = useForm<FormFieldValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      contactNumber: '',
      companySize: '',
    },
  })

  const { errors } = form.formState

  const phoneUtil = PhoneNumberUtil.getInstance()

  const isPhoneValid = (phone: string) => {
    try {
      if (phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))) {
        return true
      }

      return 'Invalid contact number'
    } catch (error) {
      return 'Invalid contact number'
    }
  }

  const seatRange = useMemo(() => {
    const ranges = [
      { label: '1-5', value: '1-5' },
      { label: '6-10', value: '6-10' },
      { label: '11-20', value: '11-20' },
      { label: '21-49', value: '21-49' },
      { label: '50+', value: '50+' },
    ]

    return ranges
  }, [])

  const onSubmit = async (values: FormFieldValues) => {
    try {
      setLoading(true)

      await fetch('/api/lead', {
        method: 'POST',
        body: JSON.stringify({
          formType,
          ...values,
          context: {
            pageName: document.title,
            consentToProcessText,
            consentToMarketingText,
          },
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      setRequested(true)
      setLoading(false)

      form.reset()
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 5000))
      setRequested(false)
    }
  }

  return (
    <Container>
      <Text tag='p' type='h4'>
        Your Details
      </Text>

      <Flex column gap={16}>
        <FieldWrapper label='First Name' value={form.watch('firstName')} error={errors.firstName?.message} required>
          <Input.Text placeholder='First Name' {...form.register('firstName', { required: 'First name required' })} />
        </FieldWrapper>

        <FieldWrapper label='Last Name' value={form.watch('lastName')} error={errors.lastName?.message} required>
          <Input.Text placeholder='Last Name' {...form.register('lastName', { required: 'Last name required' })} />
        </FieldWrapper>

        <FieldWrapper
          label='Company Name'
          value={form.watch('companyName')}
          error={errors.companyName?.message}
          required
        >
          <Input.Text
            placeholder='Company Name'
            {...form.register('companyName', { required: 'Company name required' })}
          />
        </FieldWrapper>

        <FieldWrapper label='Work Email' value={form.watch('email')} error={errors.email?.message} required>
          <Input.Text
            placeholder='name@company.com'
            autoCapitalize='none'
            {...form.register('email', {
              required: 'Work email required',
              pattern: {
                value: EmailRegex,
                message: 'Invalid email address',
              },
            })}
          />
        </FieldWrapper>

        <FieldWrapper
          label='Contact Number'
          value={form.watch('contactNumber')}
          error={errors.contactNumber?.message}
          required
        >
          <Input.Tel
            withCountrySelector
            placeholder='+44 7890 654321'
            {...form.register('contactNumber', {
              required: 'Contact number required',
              validate: (value) => isPhoneValid(value),
            })}
          />
        </FieldWrapper>

        <FieldWrapper label='Company Size' value={form.watch('companySize')}>
          <Input.Select
            placeholder='Company Size'
            options={seatRange}
            value={form.getValues('companySize')}
            {...form.register('companySize')}
          />
        </FieldWrapper>
      </Flex>

      <Flex column gap={16}>
        <Text type='body-small-400' color={scheme === 'dark' ? 'white80' : 'charcoal80Solid'}>
          {consentToProcessText}
        </Text>
        <Text type='body-small-400' color={scheme === 'dark' ? 'white80' : 'charcoal80Solid'}>
          {consentToMarketingText} {`Read more in our `}
          <NextLink href='/legal/privacy-policy'>privacy policy</NextLink>.
        </Text>

        <Flex column gap={8}>
          <Flex column gap={2}>
            <Controller
              control={form.control}
              name='consentToMarketing'
              rules={{
                validate: (value) => value === true || value === false || 'Please indicate whether you agree or not',
              }}
              render={({ field }) => (
                <Input.Checkbox
                  id='consent-to-marketing-option-1'
                  variant='radio'
                  label={acceptMarketingText}
                  value={'true'}
                  name={field.name}
                  onChange={() => {
                    field.onChange(true)
                  }}
                  checked={field.value === true}
                />
              )}
            />

            <Controller
              control={form.control}
              name='consentToMarketing'
              rules={{
                validate: (value) => value === true || value === false || 'Please indicate whether you agree or not',
              }}
              render={({ field }) => (
                <Input.Checkbox
                  id='consent-to-marketing-option-2'
                  variant='radio'
                  label={declineMarketingText}
                  value={'false'}
                  name={field.name}
                  onChange={() => {
                    field.onChange(false)
                  }}
                  checked={field.value === false}
                />
              )}
            />
          </Flex>

          {!!form.formState.errors.consentToMarketing?.message && (
            <Text tag='h5' color={scheme === 'dark' ? 'errorDark' : 'errorLight'}>
              {form.formState.errors.consentToMarketing?.message}
            </Text>
          )}
        </Flex>
      </Flex>

      <Button block loading={loading} disabled={requested} onClick={form.handleSubmit(onSubmit)}>
        {!requested ? (
          submitButtonText
        ) : (
          <Flex gap={4} alignItems='center'>
            <Icon name='tick-box' color='white' />
            Request Sent
          </Flex>
        )}
      </Button>

      {requested && (
        <>
          <Text type='body-small-550' align='center' color={scheme === 'dark' ? 'white' : 'charcoal'}>
            Thank you! We’ve received your request and will be in touch
          </Text>
          <GoogleAdsConversion type={formType} />
          <GoogleAnalyticsKeyEvent type={formType} />
          <LinkedinAdsConversion type={formType} />
        </>
      )}
    </Container>
  )
}

export default Form

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
